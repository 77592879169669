<template>
  <div class="container">
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb pl-0 mb-0 pb-2">
          <li class="breadcrumb-item"><a href="/">{{ $t('breadcrumb.main-page') }}</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.registration') }}</li>
        </ol>
      </nav>
      <div class="page-title signup-page-title text-lg-left text-center">
        {{ $t('signup.step-title-1') }}
      </div>
      <div class="title-page-mob">
        {{ $t('breadcrumb.registration') }}
      </div>
      <div class="signup-wrapper-info">
        <div class="signup-wrapper-info-left">
          <img style="margin-top: auto;margin-bottom: auto" width="74px" height="74px"
               src="/images/notification-big.svg" alt="">
          <div v-html="$t('signup.student-text')" class="signup-wrapper-info-left-text"></div>
        </div>
      </div>
      <div class="signup-requered-top">
        {{ $t('signup.requered-fields') }}
      </div>

    </div>
    <h3 class="signup-h3" v-if="this.$i18n.locale === 'ru'" style="margin: 0"> Шаг 1 из 2</h3>
    <h3 class="signup-h3" v-if="this.$i18n.locale === 'kz'" style="margin: 0">2 қадамның 1-сі</h3>
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="container p-0">
          <div class="px-4">


            <form action="" class="signin-form row">
              <div class="col-md-12 p-0">
                <div class="row">
                  <div class="col-lg-4 col-md-12">
                    <div class="signup-requered-top-mob pt-0 pb-4 text-center">
                      {{ $t('signup.requered-fields') }}
                    </div>
                    <form-group :field="$v.account.sex" :label="$t('references.gender')" name="gender"
                                class="form-group gender-mob">
                      <el-radio-group v-model="account.sex" class="w-100">
                        <el-radio-button label="gender-1">{{ $t('references.gender-male') }}</el-radio-button>
                        <el-radio-button label="gender-2">{{ $t('references.gender-female') }}</el-radio-button>
                      </el-radio-group>
                    </form-group>
                    <form-group :field="$v.account.iin"
                                :label="$t('references.required-field') + $t('signup.iin-label')" name="iin">
                      <el-input
                          maxlength="12"
                          :placeholder="$t('signup.iin-placeholder')"
                          v-model="account.iin"
                          @input="getDataFromIin"
                          @change="$v.account.iin.$touch()"
                          show-word-limit
                          clearable>
                      </el-input>
                    </form-group>
                    <form-group :field="$v.account.last_name"
                                :label="$t('references.required-field') + $t('signup.last-name-label')"
                                name="last_name">
                      <el-input
                          :placeholder="$t('signup.last-name-placeholder')"
                          v-model="account.last_name"
                          @change="$v.account.last_name.$touch()"
                          clearable>
                      </el-input>
                    </form-group>
                    <form-group :field="$v.account.first_name"
                                :label="$t('references.required-field') + $t('signup.first-name-label')"
                                name="first_name">
                      <el-input
                          :placeholder="$t('signup.first-name-placeholder')"
                          v-model="account.first_name"
                          @change="$v.account.first_name.$touch()"
                          clearable>
                      </el-input>
                    </form-group>
                    <form-group :field="$v.account.patronymic" :label="$t('signup.patronymic-label')" name="patronymic">
                      <el-input
                          :placeholder="$t('signup.patronymic-placeholder')"
                          v-model="account.patronymic"
                          clearable>
                      </el-input>
                    </form-group>
                    <form-group :field="$v.account.birth_date"
                                :label="$t('references.required-field') + $t('signup.birth-date-label')"
                                name="birth_date">
                      <el-date-picker
                          style="width: 100%;"
                          v-model="account.birth_date"
                          type="date"
                          placeholder="Дата"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          disabled
                          @change="$v.account.birth_date.$touch()"
                      >
                      </el-date-picker>
                    </form-group>
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <form-group :field="$v.account.sex"
                                :label="$t('references.required-field') + $t('references.gender')" name="gender"
                                class="mob-hidden">
                      <el-select
                          :placeholder="$t('references.gender')"
                          v-model="account.sex">
                        <el-option
                            v-for="item in genders"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                      </el-select>
                    </form-group>
                      <form-group :field="$v.account.email" :label="$t('references.email-label')" name="email">
                        <el-input
                            @input="emailValidate"
                            id="email"
                            :placeholder="$t('references.email-placeholder')"
                            v-model="account.email"
                            @change="$v.account.email.$touch()"
                            clearable>
                        </el-input>
                      </form-group>

                    <form-group :field="$v.account.password"
                                :label="$t('references.required-field') + $t('signin-form.password-label')"
                                name="password">
                      <el-input
                          onkeyup="value=value.replace(/[^\d]/g,'')"
                          type="number"
                          maxlength="4"
                          :placeholder="$t('signin-form.password-placeholder')"
                          v-model="password"
                          @input="onPasswordInput"
                          @change="$v.password.$touch()"
                          show-password>
                      </el-input>
                    </form-group>
                    <form-group :field="$v.account.confirm_password"
                                :label="$t('references.required-field') + $t('signin-form.password-confirm-label')"
                                name="password">
                      <el-input
                          onkeyup="value=value.replace(/[^\d]/g,'')"
                          type="number"
                          maxlength="4"
                          :placeholder="$t('signin-form.password-confirm-placeholder')"
                          v-model="confirm_password"
                          @input="onPasswordConfirmInput"
                          @change="$v.confirm_password.$touch()"
                          show-password></el-input>
                    </form-group>
                    <form-group :field="$v.account.phone"
                                :label="$t('references.required-field') + $t('references.phone-label')" name="phone">
                      <el-input
                          :disabled="phoneVerified"
                          :placeholder="$t('references.phone-placeholder')"
                          v-model="phone"
                          v-mask="'+7 (###) ### ##-##'"
                          @input="enforcePhoneFormat"
                          clearable>
                      </el-input>
                    </form-group>
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <div class="col-12 px-0">
                      <p class="text-primary">{{ $t('signin-form.reg-instruction') }}</p>
                      <div style="justify-content: space-between" class="d-flex mob-column-signup">
                        <a class="download-instruction" :href="$t('signin-form.url-dock')"
                           :download="$t('signin-form.reg-instruction')">{{ $t('signin-form.download') }}</a>
                        <a @click="$bvModal.show('youtube-modal')"
                           class="download-instruction margin-download">{{ $t('signin-form.watch') }}</a>
                      </div>
                    </div>
                    <div class="col-12 px-0">
                      <p class="text-primary">{{ $t('signin-form.password-must1') }}</p>
                      <p><img src="/images/done.svg" alt="" class="mr-2"> {{ $t('signin-form.password-must2') }}</p>
                      <p><img src="/images/done-lamp.svg" alt="" class="mr-2"> {{ $t('signin-form.password-must3') }}
                      </p>
                    </div>

                    <div class="signup-additional-info pb-4 pt-1">
                      {{ $t('signin-form.registration-is-possible') }}
                    </div>
                    <div v-if="phoneVerified" class="phone-is-confirmed pb-4 pt-1">
                      <img width="15" src="/images/profile/tick-green.svg" class="mr-2">
                      {{ $t('signin-form.phone-is-confirmed') }}
                    </div>
                    <div v-if="!phoneVerified" class="confirm-phone-btn-block">
                      <button class="confirm-phone-btn" @click="openModal" type="button">
                        {{ $t('signin-form.confirm-phone') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 pt-2 px-0">
                <div class="pt-3 signup-submit-block">
                  <el-button @click="createUser" class="btn-finish ml-0"
                             :disabled=" !phoneVerified || password==false || confirm_password==false">
                   {{$t('signup.next-btn')}}
                  </el-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="youtube-modal" hide-footer>
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div>
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe width="560" height="315" :src="$t('signin-form.url-youtube')" title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="error-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('error-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <h3>{{ $t('signup.error-modal') }}</h3>
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </b-modal>

    <b-modal id="sms-modal" hide-footer hide-header centered>
      <button class="btn-close-top" @click="$bvModal.hide('sms-modal')">
        <img width="12" src="/images/profile/close.svg" alt="">
      </button>
      <h3 class="sms-modal-title">{{ $t('signup.enter-sms-code') }}</h3>
      <!-- <span>{{ $t('signup.sended-to') }} {{phone}}</span> -->
      <span v-if="parent.phone" class="sms-modal-text">{{ $t('signup.sended-to') }} {{ parentPhone }}</span>
      <span v-else class="sms-modal-text">{{ $t('signup.sended-to') }} {{ phone }}</span>
      <div id="form" class="sms-modal-form">
        <CodeInput :field-width="44" :field-height="56" :loading="sendingSms" :fields="4" class="input"
                   v-on:change="onChange" v-on:complete="onComplete"/>
        <p class="text-danger" v-if="invalidCode">{{ $t('signup.incorrect-code') }}</p>
      </div>
      <p style="cursor: pointer" v-if="canResend"><a class="resend-sms cursor-pointer"
                                                     @click="resendSms">{{ $t('signup.resend-code') }}</a></p>
      <div v-if="!canResend" class="can-resend-sms">{{ $t('signup.resend-code-after') }}{{ resendSec }}
        {{ $t('signup.resend-code-seconds') }}
      </div>
    </b-modal>

  </div>
</template>
<script>
import Vue from 'vue';
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {minLength, minValue, required, email, helpers, sameAs} from "vuelidate/lib/validators";
import CodeInput from "vue-verification-code-input";

const passwordHelper = helpers.regex('passwordHelper', /\d{3}[0-9]/);


export default {
  name: 'Signup',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
  data() {
    return {
      smsSended: false,
      genderDisable: true,
      isHiddenStudent: false,
      isHiddenParent: true,
      iinCorrect: true,
      emailCorrect: true,
      errorMessage: '',
      education: {},
      localities: [],
      regions: [],
      cities: [],
      phone: '',
      smsSendEror: false,
      parentPhone: '',
      sendingSms: false,
      regionsLoading: false,
      localitiesLoading: false,
      citiesLoading: false,
      invalidCode: false,
      canResend: true,
      phoneVerified: false,
      parentPhoneVerified: false,
      resendSec: 300,
      acceptData: false,
      rules: [
        {active: false, message: this.$t('reset-password.number-required'), regex: /\d{3}[1-9]/},

      ],
      genderLabel: '',
      submitted: false,
      passwordValid: false,
      password: '',
      confirm_password: '',
      genders: [
        {
          id: "1",
          name: this.$t('references.gender-male')
        },
        {
          id: "2",
          name: this.$t('references.gender-female')
        }
      ],
      role: '1',
      account: {
        education: {
          city_id: '',
          class: '',
          class_for_admission: '',
          id: '',
          instruction_language: '',
          locality_id: '',
          region_id: '',
          school_id: '',
          test_language: '',
        },
        parent: {
          additional_phone: '',
          created_at: '',
          email: '',
          first_name: '',
          id: '',
          last_name: '',
          patronymic: '',
          phone: '',
          updated_at: '',
          user_id: '',
        },
        iin: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        birth_date: '',
        locality_id: '',
        region_id: '',
        city_id: '',
        phone: '',
        email: '',
        sex: '',
        gender: '',
        password: '',
        confirm_password: '',
      },
      parent: {
        gendernum: '',
        iin: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        birth_date: '',
        phone: '',
        email: '',
        password: '',
        passwordConfirm: '',
      }
    }
  },
  validations: {
    phone: {
      required: false,
      minLength: minLength(18)
    },

    parentPhone: {
      required: false,
      minLength: minLength(18)
    },
    password: {
      required, passwordHelper, minLength: minLength(4),
      valid: function (value) {
        return /\d{3}[0-9]/.test(value)
      },

    },

    confirm_password: {
      required, passwordHelper, minLength: minLength(4),
      sameAsPassword: sameAs('password'),
      valid: function (value) {
        return /\d{3}[0-9]/.test(value)

      },

    },
    account: {
      iin: {required, minLength: minLength(12)},
      phone: {required, minLength: minLength(11)},
      email: {email, required: false},
      last_name: {required},
      first_name: {required},
      sex: {required},
      birth_date: {required},

      password: {
        required, passwordHelper, minLength: minLength(4),
        valid: function (value) {
          return /\d{3}[0-9]/.test(value)
        },

      },

      confirm_password: {
        required, passwordHelper, minLength: minLength(4),
        sameAsPassword: sameAs('password'),
        valid: function (value) {
          return /\d{3}[0-9]/.test(value)

        },

      },

    },
  },
  computed: {
    notSamePasswords() {
      if (this.passwordsFilled) {
        return (this.password !== this.confirm_password)
      } else {
        return false
      }
    },
    passwordsFilled() {
      return (this.password !== '' && this.confirm_password !== '')
    },
    passwordValidation() {
      let errors = []
      for (let condition of this.rules) {
        condition.active = condition.regex.test(this.password);
        // if (!condition.regex.test(this.password)) {
        errors.push({
          message: condition.message,
          active: condition.active
        });
        // }
      }
      let error = false;
      Object.entries(errors).forEach(entry => {
        const [key, value] = entry;
        if (value.active == false) {
          error = true;
        }
      });
      this.passwordValid = !error;
      return {valid: !error, errors}
    }
  },
  mounted() {
    this.isHiddenStudent = false;
    this.isHiddenParent = true;
    this.role = '1'
    if (sessionStorage.getItem('phone-verified')) {
      this.phoneVerified = (sessionStorage.getItem('phone-verified') == 'true');
    }
    if (sessionStorage.getItem('account')) {
      this.account = JSON.parse(sessionStorage.getItem('account'));
      this.account.sex=this.account.sex.toString();
      function format(value, pattern) {
        var i = 0,
            v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
      }

      this.phone = format(this.account.phone, '+# (###) ### ##-##');
      this.password = this.account.password;
      this.confirm_password = this.account.confirm_password
      this.enforcePhoneFormat();
      this.$v.$touch()
    }
    if (this.account.email == null) {
      this.account.email = '';
    }
  },
  methods: {
    emailValidate(val) {
      if (val != '') {
        this.emailCorrect = false;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var emailBool = (re.test(String(val).toLowerCase()))

        if (emailBool == true) {
          this.$http.get(window.API_ROOT + '/api/check-email?email=' + val).then((res) => {
            console.log("res.body.message", res)
            if (res.body != false) {
              if (res.body.message != 'ok') {
                this.emailCorrect = false;
                this.errorMessage = res.body.email[0];
                this.$bvModal.show('error-modal');
              } else if (res.body.message == 'ok') {
                this.emailCorrect = true;
              }
            }
          })
        } else {
          this.errorMessage = this.$t('form-group.email-required');

        }
      }
      if (val == '') {

        this.emailCorrect = true;
      }
    },
    getDataFromIin(val) {

        if (val.length == 12) {
          if(/^[\d]+$/.test(val)==false){
            this.account.iin=''
            val=''
            this.errorMessage = this.$t('profile.settings.iin-error')
            this.$bvModal.show('error-modal');

            return
          }
          this.$http.post(window.API_ROOT + '/api/check-iin', {
            iin: val,
            role: this.role
          })
              .then((res) => {
                if (res.body.status == 'error') {
                  this.iinCorrect = false;
                  this.errorMessage = res.body.error[0];
                  this.$bvModal.show('error-modal');
                }
                if (res.body.status == 'success') {
                  let date = new Date();
                  this.iinCorrect = true;
                  let year;
                  if (parseInt('20' + val.slice(0, 2), 10) > date.getFullYear()) {
                    year = parseInt('19' + val.slice(0, 2), 10);
                  } else {
                    year = parseInt('20' + val.slice(0, 2), 10);
                  }

                  let month = val.slice(2, 4)
                  let day = val.slice(4, 6)

                  if (this.role == '1') {
                    this.iinCorrect = true;
                    this.account.birth_date = `${year.toString()}-${month}-${day}`;


                    if (this.account.iin[6] == 3 || this.account.iin[6] == 5 || this.account.iin[6] == 7 || this.account.iin[6] == 9) {
                      this.account.sex = this.$t('references.gender-male');

                      this.genderLabel = this.$t('references.gender-male');
                    } else if (this.account.iin[6] == 4 || this.account.iin[6] == 6 || this.account.iin[6] == 8) {
                      this.genderLabel = this.$t('references.gender-female');
                      this.account.sex = this.$t('references.gender-female');
                    } else if (this.account.iin[6] == 0) {
                      this.genderDisable = false
                    } else {
                      this.account.sex = '';
                    }
                  } else {
                    this.iinCorrect = true;
                    this.parent.birth_date = `${year.toString()}-${month}-${day}`;
                    if (this.parent.iin[6] == 3 || this.parent.iin[6] == 5) {
                      this.parent.gendernum = this.$t('references.gender-male');
                    } else if (this.parent.iin[6] == 4 || this.parent.iin[6] == 6) {
                      this.parent.gendernum = this.$t('references.gender-female');
                    } else {
                      this.parent.gender = '';
                      this.parent.gendernum = '';
                    }
                  }

                }

              })
              .catch((e) => {
                Vue.toastr({
                  message: 'Error',
                  description: e.message,
                  type: 'error'
                })
              })

      }


    },
    openModal() {

      if (this.iinCorrect == false) {
        this.$bvModal.show('error-modal');
        return;
      }
      this.$v.account.$touch();
      if (this.$v.account.$invalid) return
      if (!this.phoneVerified) {

        if (this.resendSec == 300) {
          setInterval(() => {
            this.resendSec--;
            if (this.resendSec == 0) {
              this.canResend = true;
            }
          }, 1000);
        }
        if (this.canResend == true) {
          this.canResend = false
          this.sendSms({
            phone: this.account.phone,
            locale: this.$i18n.locale
          }).then((res) => {
            this.resendSec == 300
            this.smsSended = true
            if (this.smsSendEror == false) {
              this.$bvModal.show('sms-modal');
            }
            this.smsSendEror = false
          }).catch(() => {
            this.smsSendEror = true
            this.smsSended = false
            this.$bvModal.hide('sms-modal');
            Vue.toastr({
              message: this.$t('profile.settings.error'),
              description: this.$t('signin-form.sms-error'),
              type: 'error'
            });
          })
        } else {
          if (this.smsSendEror == true) {
            this.sendSms({
              phone: this.account.phone,
              locale: this.$i18n.locale
            }).then((res) => {
              this.resendSec == 300
              this.smsSended = true
              if (this.smsSendEror == false) {
                this.$bvModal.show('sms-modal');
              }
              this.smsSendEror = false
            }).catch(() => {
              this.smsSendEror = true
              this.smsSended = false
              this.$bvModal.hide('sms-modal');
              Vue.toastr({
                message: this.$t('profile.settings.error'),
                description: this.$t('signin-form.sms-error'),
                type: 'error'
              });
            })
          }
          if (this.smsSendEror == false) {
            this.$bvModal.show('sms-modal');
          }
        }
      }
    },
    resendSms() {
      if (this.role == '1') {
        this.canResend = false
        this.sendSms({
          phone: this.account.phone,
          locale: this.$i18n.locale
        }).then((res) => {
          this.$bvModal.show('sms-modal');
        }).catch(() => {
          this.$bvModal.hide('sms-modal');
          this.smsSendEror = true
          this.canResend = true
          Vue.toastr({
            message: this.$t('profile.settings.error'),
            description: this.$t('signin-form.sms-error'),
            type: 'error'
          });
        })
        this.resendSec = 300
        if (this.resendSec == 300) {
          setInterval(() => {
            this.resendSec--;
            if (this.resendSec == 0) {
              this.canResend = true;
            }
          }, 1000);
        }
      }
    },
    enforcePhoneFormat() {
      this.$v.phone.$touch();
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.account.phone = x[0];
    },
    onChange(v) {
      // console.log('on change');
    },
    onComplete(v) {
      this.invalidCode = false;
      this.sendingSms = true;
      this.$http.post(window.API_ROOT + '/api/sms-verification', {
        phone: (this.role == '1') ? this.account.phone : this.parent.phone,
        code: v,
      }).then((res) => {
        if (res.body.status == 'success') {
          this.phoneVerified = true
          sessionStorage.setItem('account', JSON.stringify(this.account))
          sessionStorage.setItem('phone-verified', this.phoneVerified)
          this.$bvModal.hide('sms-modal');
        }
        if (res.body.status == 'error') {
          this.invalidCode = true;
        }
        this.sendingSms = false;
      }).catch(() => {
        this.sendingSms = false;
      })
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    onSubmit(data) {
      this.login(data)
          .then(() => {
            this.$router.push(this.$route.query.redirect || '/')

          })
          .catch(() => {
          })
    },
    onPasswordInput(e) {
      this.account.password = this.password;
    },
    onPasswordConfirmInput(e) {
      this.account.confirm_password = this.confirm_password;
    },

    createUser() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true;
        let form = {};
        let phoneVerified = false;
        if (this.role == '1') {
          form = this.account;
          phoneVerified = this.phoneVerified;
        } else {
          form = this.parent;
          phoneVerified = this.parentPhoneVerified;
        }
        form.role = this.role;
        if (phoneVerified) {

          if (this.account.sex == this.$t('references.gender-male')) {
            this.account.sex = 1;
          } else if (this.account.sex == this.$t('references.gender-female')) {
            this.account.sex = 2;
          }
          sessionStorage.setItem('account', JSON.stringify(this.account))
          sessionStorage.setItem('phone-verified', this.phoneVerified)
          this.$router.push('/signup-2-step')
        } else {
          this.errorMessage = this.$t('profile.settings.please-submit-phone');
          this.$bvModal.show('error-modal');
        }
      } else {
        Vue.toastr({
          message: 'error',
          description: this.$t('profile.settings.all-data-complete'),
          type: 'error'
        })
      }
    },
    ...mapActions({
      'sendSms': 'sendSms',
      'checkSms': 'checkSms'
    })
  }
}
</script>

<style>
.signup-wrapper-info-right-text {
  max-width: 363px;
  margin-left: 50px;
  font-size: 14px;
  line-height: 150%;
}

.signup-wrapper-info-border {
  height: 100%;
  margin-left: 85px;
  border-right: 2px solid #18A0FB;;
}

.signup-wrapper-info-left-text {
  margin-left: 50px;
  font-size: 14px;
  line-height: 150%;
}

.signup-wrapper-info-right {
  width: 50%;
}

.signup-wrapper-info-left {
  width: 70%;
  display: flex;
}

.signup-wrapper-info {
  margin-top: 65px;
  display: flex;
  flex-wrap: wrap;
}

.toggle-wrapper {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
}

.toggle-title {
  margin-top: 30px;
  color: #18A0FB;
}

.toggle-button {
  user-select: none;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
  width: 200px;
  line-height: 46px;
  font-weight: 500;
  font-size: 13px;
  color: #18A0FB;
  background: #F7F8F9;
  border-radius: 10px;
}

.toggle-active-button {
  user-select: none;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  width: 200px;
  line-height: 46px;
  font-weight: 500;
  font-size: 13px;
  color: white;
  background: #18A0FB;
  border-radius: 10px;
}

.margin-download {
  margin-left: 3rem;
}

.download-instruction {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: none;
  max-width: 184px;
  display: block;
  padding: 1px 16px;
  position: static;
  height: 32px;

  font-size: 13px;
  line-height: 32px;
  text-align: center;
  color: #0070D2;
  flex: none;
  order: 1;
  flex-grow: 0;
  background: #FFFFFF;
  border: 1px solid #DDDBDA;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 30px;
}

.download-instruction:hover {
  text-decoration: none;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.el-select {
  margin-bottom: 0;
}

.checkbox-block {
  display: flex;
  flex-direction: row;
}

.form-check-label {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #eee;
  border-radius: 50%;
}

.form-check-label:hover input ~ .checkmark {
  background-color: #eee;
}

.form-check-label input:checked ~ .checkmark {
  background-color: #18A0FB;
}

.form-check-label input:checked .form-check-label {
  background-color: #18A0FB;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.form-check-label .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.resend-sms {
  font-size: 16px;
  color: #333;
  display: block;
  margin-top: 20px;
}

.el-button.btn-finish.is-disabled {
  background: #CACACA;
  border: 1px solid #CACACA;
  color: #FFFFFF;
}

.btn-finish {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background: #18A0FB;
  border-radius: 5px;
  padding: 13px 44px;
  color: #FFFFFF;
  margin: 0;
}

.btn-finish:hover, .btn-finish:focus {
  color: #FFFFFF;
  background: #18A0FB;
}

.signup-requered-top-mob {
  display: none;
}

.signup-accept-block {
  display: flex;
  flex-direction: column;
}

.signup-submit-block {
  display: flex;
  justify-content: left;
}

.gender-mob {
  display: none;
}

@media (max-width: 991px) {
  .signin-form {
    box-shadow: 0px 4px 4px rgb(51 51 51 / 4%), 0px 4px 16px rgb(51 51 51 / 8%);
    border-radius: 8px;
    padding: 25px 21px;
    background: #fff;
  }

  .signup-requered-top {
    display: none;
  }

  .signup-requered-top-mob {
    display: block;
  }

  .signup-accept-block {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 16px;
  }

  .signup-requered-fields {
    margin-top: 16px;
  }

  .signup-submit-block {
    justify-content: center;
  }

  .gender-mob {
    display: none;
  }

  .gender-mob .el-radio-group {
    text-align: center;
  }

  .gender-mob .el-radio-button__inner {
    padding: 10px 31px;

  }

  .gender-mob .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #18A0FB;
  }

  /* .mob-hidden {
    display: none;
  } */
}

@media (max-width: 768px) {
  .btn-block {
    text-align: center;
  }
}

@media (max-width: 430px) {
  .margin-download {
    margin-left: 0;
  }

  .download-instruction {
    max-width: 100%;
  }

  .mob-column-signup {
    flex-direction: column !important
  }
}

@media (max-width: 499px) {
  .toggle-button {
    width: 100%;

  }

  .toggle-active-button {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .signup-h3{
    margin-top: 2rem!important;
    margin-bottom: 2rem!important;
    text-align: center;
  }
  .signup-wrapper-info {
    flex-direction: column;
  }

  .toggle-wrapper {
    justify-content: center;
  }

  .signup-wrapper-info-left-text {
    max-width: unset;
  }

  .signup-wrapper-info-left {
    width: 100%
  }

  .signup-wrapper-info-right {
    width: 100%;
  }

  .signup-wrapper-info-right-text {
    margin-left: 123px;
    margin-top: 2rem;
    max-width: unset;
  }

  .signup-wrapper-info-border {
    display: none;
  }
}
</style>